@import "./variables.less";

.dashboard-container {
    height: 100%;
    .widgets-container {
        height: calc(100vh - 130px);
        overflow: auto;
        scrollbar-color: #f5f6f8 #f5f6f8;
        &:hover {
            scrollbar-color: #c2c2c2 #f5f6f8;
        }
    }
    .add-widget-icon {
        svg {
            height: 50px;
            width: 50px;
            cursor: pointer;
            padding-left: 10px;
        }
    }
    .refresh-button {
        margin-right: 8px;
        color: @alpha-font-color-gray;
    }
    .alpha-icon.refresh {
        width: 15px;
        height: 17px;
        vertical-align: text-top;
    }
    .date-container {
        margin-top: 50px;
        font-size: 22px;
        width: 100%;
        display: flex;
        flex-flow: row-reverse;
    }
    .card {
        background: #fff;
        box-shadow: 0px 1px 4px 0px #00000026;
        .editor {
            position: absolute;
            right: 22px;
            top: 26px;
            border: none;
        }
        border: 1px solid #dadada;
        border-radius: 5px;
        height: 350px;
        margin: 10px;
        padding: 18px;

        .period-selector {
            width: 30%;
            float: right;
            span {
                font-size: 12px;
            }
        }

        .section-title {
            margin-bottom: 10px;
            font-weight: 500;
            &.last-activities {
                display: inline-block;
            }
        }

        .grouped-card-container,
        .projects-card-container {
            cursor: pointer;
            height: 280px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: safe center;
            .grouped-widget-container,
            .project-data-container {
                display: flex;
                align-items: center;
                padding: 8px 12px 8px 12px;
                margin-bottom: 10px;
                border-radius: 5px;
                font-size: 12px;
                .progress-indicator {
                    width: 250px;
                    height: 10px;
                    background-color: #f5f6f8;
                    border-radius: 2px;
                }
                .pecentage {
                    margin-left: 10px;
                    font-size: 12px;
                }
                > span {
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: 26%;
                    text-align: right;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                &:hover {
                    background: #f5f6f8;
                    .progress-indicator {
                        background: #fff;
                    }
                }
            }
        }
        .view-all {
            margin-top: 10px;
            color: @main-blue;
            cursor: pointer;
        }
        .list-widget-card-container {
            overflow: auto;
            height: 79%;
            .task-item-container {
                font-size: 12px;
                cursor: pointer;
                padding: 8px 12px 8px 12px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                .indicator {
                    height: 10px;
                    width: 10px;
                    display: inline-block;
                    border-radius: 2px;
                    background: @main-blue;
                    margin-right: 7px;
                }
                &:hover {
                    background: #f5f6f8;
                }
            }
            &.numeric {
                display: flex;
                align-items: center;
                justify-content: center;
                h1 {
                    font-size: 36px;
                    cursor: pointer;
                }
            }
        }
        .activities-card-container {
            margin-top: 10px;
            overflow: auto;
            height: 90%;
            .project-selector {
                margin-bottom: 10px;
                width: 30%;
                span > {
                    font-size: 12px;
                }
            }
            .label {
                color: @alpha-secondary-font-color;
                font-size: 12px;
                margin-bottom: 10px;
                font-weight: 500;
            }
            .activities-row {
                display: flex;
                justify-content: space-between;
                .activity-container {
                    width: 120px;
                    text-align: center;
                    cursor: pointer;
                    &.disabled {
                        pointer-events: none;
                    }
                    .count {
                        font-size: 22px;
                        font-weight: 700;
                        &.nullable {
                            color: @alpha-font-color-gray;
                        }
                        &:hover:not(.nullable) {
                            color: @main-blue;
                        }
                    }
                    .title {
                        color: @alpha-secondary-font-color;
                        font-size: 12px;
                    }
                }
            }
            .ant-divider {
                margin: 12px 0;
            }
        }

        &.statistics-chart {
            .header {
                height: 34px;
                .ant-select {
                    float: right;
                    width: 150px;
                }
            }
            .statistics-container {
                display: flex;
                margin-left: 40px;
                height: 84%;
                .recharts-wrapper {
                    //TODO: find better way
                    width: 400px !important; //Override recharts style
                    margin: auto;
                    .recharts-layer {
                        font-size: 12px;
                    }
                }
                .cards-container {
                    display: flex;
                    justify-content: center;
                    height: 216px;
                    flex-direction: column;
                    gap: 10px;
                    overflow: auto;
                    font-size: 12px;
                    .ant-divider {
                        margin: 10px 0;
                    }
                    width: 100%;
                    margin: auto;
                    margin-left: 30px;
                    .card {
                        height: fit-content;
                        border: 1px solid #273c75;
                        border-radius: 8px;
                        padding: 10px 20px 10px 20px;
                        min-width: 150px;
                        color: #858585;
                    }
                    .color-indicator {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        margin-right: 5px;
                        border-radius: 50%;
                    }
                    .legend-label:hover {
                        color: @main-blue;
                    }
                }
            }
        }
        .empty-card-title {
            position: absolute;
            top: 50%;
            right: 39%;
            margin: auto;
            color: @alpha-font-color-gray;
            font-size: 12px;
        }
    }
    .card:hover {
        .close-icon-container {
            display: block;
        }
    }
}
.alpha-dropdown-overlay.widget-editor {
    width: 200px;
    li {
        padding: 10px;
        i {
            vertical-align: middle;
            margin-right: 10px;
        }
        &:nth-child(2) {
            color: #f5222d;
        }
    }
}

@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;