.react-pdf__Page {
    .react-pdf__Page__canvas {
        margin: 20px auto 0 auto;
    }
    .react-pdf__message--no-data {
        display: none;
    }
}

.document-preview-dialog {
    .ant-modal-header {
        font-size: 16px;
        line-height: 19px;
        padding: 13px 20px 13px 0px;
    }

    .ant-modal-body {
        max-height: calc(100vh - 140px);
        overflow: auto;
    }

    .ant-modal-footer {
        padding: 15px 20px;

        button {
            height: 40px;
        }
    }
}

@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;