@import "./variables.less";

.ant-table-wrapper.alpha-table {
    .ant-table-expanded-row > td:first-of-type {
        background: white;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr.ant-table-row:hover > td:first-child:before {
        background: @alpha-background-gray;
    }

    .ant-table-expanded-row .ant-table-thead > tr {
        height: 47px;
    }
    tr.parent-nested-row {
        cursor: pointer;
    }
    th .ant-table-selection {
        height: 13px;
    }

    .ant-table-thead,
    .ant-table.ant-table-small .ant-table-thead {
        th.ant-table-column-has-sorters,
        th.ant-table-selection-column {
            padding: 0;
            padding-left: 13px;
            // override antd header column dividers and sorter styles
            div.ant-table-column-sorters {
                display: inline-flex;
                .ant-table-column-sorter {
                    margin-left: 8px;
                    .anticon.active {
                        color: @main-blue;
                    }
                }
            }
        }
    }
    th.ant-table-cell::before {
        display: none;
    }
}

.ant-table-wrapper.alpha-table {
    .ant-table,
    .ant-table-container,
    table {
        border-radius: 0;
    }

    .ant-table-thead > tr > th {
        background-color: white;
        border-radius: 0px !important;
        border-top: 1px solid @alpha-border-color;
        color: @alpha-secondary-font-color;
        font-weight: 500;
        line-height: 13px;
        &.isFiltered {
            background: @main-blue;
            * {
                color: #fff;
            }
            .ant-table-column-sorter-up.active,
            .anticon-caret-down.active {
                * {
                    color: #979191;
                }
            }
        }
    }
    .ant-table-expanded-row .ant-table-thead > tr > th {
        border-top: none;
    }

    .ant-table-thead
        > tr
        > th:not(.isFiltered).ant-table-column-has-sorters:hover {
        background: #f2f2f2;
    }

    .ant-table-thead > tr > tr,
    .ant-table-tbody > tr > td,
    .ant-table.ant-table-small .ant-table-tbody > tr > td,
    .ant-table-tfoot > tr > td,
    .ant-table-tfoot > tr > th {
        padding: 12px 15px;
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background: white;
    }

    .ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
        background: #fafafa;
    }

    .ant-table-tbody > tr.ant-table-row-selected:hover > td {
        background: @alpha-secondary-background-color;
    }

    .ant-table-tbody > tr.ant-table-row-selected > td {
        border-bottom: 1px solid @alpha-border-color;
    }

    .ant-table-tbody > tr > td {
        font-weight: 400;
    }

    .row-actions-wrapper {
        height: 21px;
        visibility: hidden;
    }

    tr:hover .row-actions-wrapper {
        visibility: visible;
        white-space: nowrap;
    }

    .virtual-table-cell-last {
        .overlaying-row-actions {
            .cell-actions {
                margin-top: -5px;
            }
        }
    }

    .overlaying-row-actions {
        .cell-actions {
            display: none;

            .ant-btn.ant-btn-sm {
                padding: 0 5px;
            }
        }
        .cell-actions.show-actions {
            display: block;
        }
    }

    div.virtual-table-cell:hover ~ .virtual-table-cell-last,
    .virtual-table-cell-last:hover {
        .overlaying-row-actions {
            .cell-text {
                display: none;
            }
            .cell-actions {
                display: block;
            }
        }
        .table-operation {
            display: block;
        }
    }

    div.virtual-table-cell:hover
        ~ .virtual-table-cell-last
        ~ .virtual-table-cell-last {
        .overlaying-row-actions {
            .cell-text {
                display: block;
            }
            .cell-actions {
                display: none;
            }
        }
        .table-operation {
            display: none;
        }
    }
    .tags-group-table-row .table-operation,
    .tags-table-row .table-operation,
    .rules-table-row .table-operation,
    .parent-nested-row .table-operation {
        button:not(.ant-switch) {
            visibility: hidden;
        }
        height: 22px;
    }
    .tags-group-table-row:hover,
    .tags-table-row:hover,
    .rules-table-row:hover,
    .parent-nested-row:hover {
        .table-operation button {
            visibility: visible;
        }
    }
}

@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;