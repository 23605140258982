@import "./variables.less";

.alpha-document-container {
    display: flex;
    margin: -20px -40px 0px;
    max-width: calc(100% + 80px);

    .highlighted-xml-node {
        box-shadow: 1px 1px 5px 4px rgb(109 133 199);
    }

    .alpha-document-minimap {
        padding-top: 30px;

        .alpha-doc-content {
            margin: 0 215px 0 115px;
            width: calc(100% - 130px);
        }
    }

    .pdf-content {
        flex: 1 1;
        position: relative;
        overflow: auto;
        height: 100vh;
        overflow-x: hidden;
        padding: 20px 165px 0;
        background: @alpha-background-gray;
    }

    .loading-ui {
        // align-content: center;
        // display: flex;
        // flex-direction: column;
        // height: 100%;
        // justify-content: center;
        text-align: center;
        position: absolute;
        left: 0;
        top: 50px;
        right: 0;
        bottom: 0;
        line-height: 100vh;
        z-index: 1005;
        background: #d3d3d324;
        overflow: hidden;
    }

    .leftColumnContainer {
        background: white;
        height: 100%;
        position: relative;

        .alpha-application-form-container {
            height: 100%;

            .alpha-application-form {
                display: flex;
                flex-direction: column;
                height: 100%;

                .ant-input {
                    height: @dynamic-ui-input-height;
                    padding: 12px 10px;
                }

                .input-mask {
                    height: @dynamic-ui-input-height;
                    width: 100%;
                    border: 1px solid #d9d9d9;
                    border-radius: 4px;
                    padding: 0px 11px;
                }
                .input-mask:hover {
                    border-color: #405482;
                }
                .input-mask:focus {
                    box-shadow: 0 0 0 2px rgb(39 60 117 / 20%);
                    outline: none;
                }

                .ant-input-number {
                    height: @dynamic-ui-input-height;
                    padding-top: 4px;
                    width: 100%;
                }

                .texteditor-btn {
                    max-width: 100%;
                    text-overflow: ellipsis;
                    white-space: normal;
                    height: auto;
                    max-height: 120px;
                    overflow: hidden;
                    padding: 8px 12px 8px;
                    width: 100%;
                    text-align: left;
                    white-space: pre-wrap;

                    span {
                        max-height: 110px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .scrollbar-container {
                        max-height: 110px;
                    }
                }

                .ant-input-disabled:not(.iota-contract-shadow) {
                    border-color: #d9d9d9;
                    box-shadow: none;
                }

                .ant-select-single {
                    .ant-select-selection-item {
                        line-height: @dynamic-ui-input-height;
                    }
                }

                .ant-select-selector {
                    min-height: @dynamic-ui-input-height;
                }

                .ant-col.ant-form-item-label {
                    padding-bottom: 0px;
                    position: relative;

                    label {
                        display: inline-block;
                        width: 100%;

                        .dynamic-modal-form-toggle {
                            float: right;
                        }
                    }

                    .alpha-doc-control-label {
                        span.alpha-icon,
                        i.alpha-icon {
                            margin: 0;
                            position: absolute;
                            right: 0px;
                        }
                    }

                    .alpha-doc-control-label.with-controls {
                        span.alpha-icon,
                        i.alpha-icon {
                            position: unset;
                        }

                        .controls-container {
                            flex: 1 1;
                            height: 13px;
                            text-align: right;
                            .comments-icon {
                                cursor: pointer;
                                margin-right: 2px;
                                svg {
                                    height: 14px;
                                    width: 12px;
                                    margin-bottom: 2px;
                                    margin-left: 2px;
                                }
                                &.has-comments {
                                    color: green;
                                }
                            }
                        }

                        .alpha-icon.xs.gear {
                            margin-top: -3px;
                        }
                    }
                }

                .ant-form-item:not(.checkbox-scan-result) {
                    .alpha-doc-control-label {
                        .alpha-doc-control-name {
                            display: inline-block;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                        }
                    }

                    .alpha-doc-control-label.with-controls {
                        display: inline-flex;
                        width: 100%;

                        .alpha-doc-control-name {
                            flex: 1 1 100%;
                        }

                        .dynamic-modal-form-toggle {
                            float: none;
                            margin-right: 0 !important;
                        }

                        .confidenece-level {
                            float: none !important;
                            margin-right: 4px !important;
                            margin-left: 4px;
                        }

                        .dynamic-modal-form-toggle,
                        .confidenece-level {
                            margin-top: -2px;
                        }

                        .controls-container {
                            align-items: center;
                            display: inline-flex;
                            justify-content: flex-end;
                            height: 15px;
                            .comments-count {
                                position: absolute;
                                right: 22px;
                                font-size: 7px;
                                bottom: 2px;
                            }
                        }
                    }
                }

                .alpha-form-title-container {
                    margin-left: 45px;
                    flex: 0 0;
                    padding: 30px 20px 30px 30px;
                    background: white;

                    h1 {
                        font-weight: 700;
                        font-size: 34px;
                        line-height: 40px;
                        margin-bottom: 10px;
                        max-width: calc(100% - 160px);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    h3 {
                        color: @alpha-secondary-font-color;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 16px;
                        margin-bottom: 0px;
                        margin-top: 10px;
                    }

                    .app-form-controls {
                        position: absolute;
                        top: 30px;
                        right: 20px;

                        .reload-button {
                            color: @alpha-font-color-gray;
                            padding: 4px 8px;
                            vertical-align: middle;

                            .alpha-icon {
                                height: 16px;
                                margin: 0;
                                vertical-align: text-top;
                                width: 16px;
                            }
                        }
                    }

                    .submit-button {
                        height: 40px;
                        margin-left: 6px;
                        margin-bottom: 0px;
                    }
                }

                .alpha-form-controls {
                    display: flex;
                    flex-direction: column;
                    flex: 1 1;
                    max-height: 100%;
                    overflow: auto;

                    .alpha-form-header {
                        font-size: 16px;
                        font-weight: 700;
                        margin-bottom: 0px;
                        line-height: 19px;
                        width: 100%;
                        display: inline;
                    }
                    .alpha-layer-section {
                        width: 100%;
                    }
                    .alpha-layer-header {
                        .ant-collapse-header {
                            margin-bottom: 8px;
                            margin-left: 0;
                            margin-top: 13px;
                            padding-bottom: 0;
                            padding-top: 0;
                            width: 100%;
                            display: flex;
                            align-items: center;
                        }
                        > div > h1 {
                            color: @alpha-font-color-black;
                            font-size: 14px;
                            margin-bottom: 0;
                        }
                        > h1 {
                            flex-grow: 1;
                        }
                        .ant-collapse-content {
                            margin-left: 5px;
                        }
                    }

                    .alpha-layer-header > div > i > svg {
                        color: #6f7cc4;
                    }

                    .alpha-layer-section-header > div > h1 {
                        font-size: 13px;
                        color: @alpha-font-color-gray;
                    }

                    .alpha-layer-section-header > div > i > svg {
                        color: @alpha-font-color-gray;
                    }
                    .scan-results-flex-container {
                        flex: 1 1;
                        .ant-input-number.decimal-type
                            > .ant-input-number-handler-wrap {
                            display: none;
                        }

                        .ant-form-item:not(.alpha-application-form-buttons):not(
                                .checkbox-scan-result
                            ):not(.editable-section-name):not(
                                .alpha-document-title
                            ) {
                            display: inline-block;
                            max-width: calc(50% - 10px);
                            flex: 0 0 calc(50% - 10px);
                            margin-bottom: 23px;

                            .ant-picker {
                                height: @dynamic-ui-input-height;
                                width: 100%;
                            }

                            .ant-col.ant-form-item-label {
                                line-height: 13px;
                                width: 100%;

                                label {
                                    font-size: 11px;
                                    color: @alpha-font-color-gray;
                                    height: auto;
                                    margin-bottom: 5px;
                                }
                            }
                        }

                        .ant-form-item.alpha-document-title:not(
                                .alpha-application-form-buttons
                            ):not(.checkbox-scan-result),
                        .ant-form-item.wide:not(
                                .alpha-application-form-buttons
                            ):not(.checkbox-scan-result) {
                            // margin: 0 18px 22px;
                            flex: 1 1 100%;
                            max-width: 100%;
                        }

                        .container-inputs-table {
                            table {
                                table-layout: fixed !important;

                                td {
                                    .ant-row.ant-form-item:not(
                                            .scan-results-dynamic-button
                                        ) {
                                        width: 100% !important; // must override all other width configurations in order to stay in bounds of layout
                                        max-width: unset !important;
                                    }

                                    .scan-results-dynamic-button {
                                        padding-top: 12px;
                                        width: 42px;
                                        .alpha-icon {
                                            vertical-align: bottom;
                                        }
                                        button {
                                            margin-top: 10px;
                                            height: 42px;
                                            border: unset;
                                            width: 10px;
                                            padding: 0;
                                        }
                                    }
                                }

                                thead {
                                    display: none;
                                }
                                td:nth-child(1) {
                                    padding-left: unset;
                                    padding-right: unset;
                                }
                                td:last-child {
                                    padding-left: unset;
                                }
                            }
                            .ant-table-footer {
                                background: transparent;
                                padding-left: 0;
                            }
                        }

                        .container-inputs-table {
                            margin-top: 10px;
                            tr th {
                                background: @alpha-background-gray-dark;
                            }

                            .ant-table-tbody {
                                tr:hover td {
                                    background: transparent;
                                }

                                .ant-row.ant-form-item {
                                    margin: 0;

                                    .ant-form-item-label {
                                        label {
                                            height: 12px;
                                        }
                                    }
                                }

                                .ant-row.ant-form-item.wide:not(
                                        .alpha-application-form-buttons
                                    ) {
                                    width: unset;
                                }

                                tr td {
                                    border-top: none;
                                    border-bottom: none;
                                }

                                tr td:not(:first-of-type) {
                                    border-left: none;
                                }
                            }
                        }
                        .editable-section-name {
                            margin-bottom: 0;
                            input {
                                border: none;
                            }
                            input:focus {
                                box-shadow: none;
                                border-bottom: 1px dashed #9ba0aa;
                            }
                        }
                    }

                    .ant-row.ant-form-item.alpha-application-form-buttons {
                        flex: 0 0;
                        margin: 10px 18px;
                        padding-top: 10px;

                        .step-name-label {
                            font-size: 13px;
                            color: @alpha-font-color-gray;
                        }

                        .ant-btn {
                            padding: 10px 15px;
                            height: 38px;
                            line-height: 16px;
                        }

                        .submit-button {
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }

    .rightColumnContainer {
        .field-highlight-block {
            cursor: pointer;
            background: #33f14a52;
            z-index: 1000;
        }

        .field-document-block {
            border: 1px dotted #ff4141;
            cursor: pointer;
            opacity: 0.5;
            z-index: 999;
        }

        .field-document-block:hover {
            background-color: rgba(186, 175, 175, 0.33);
        }

        .ant-tabs-nav {
            background: @alpha-background-gray;
            position: absolute;
            bottom: 18px;
            margin-left: 12px;
            z-index: 1000;

            .ant-tabs-nav-operations {
                width: unset !important; // To avoid antd tabBarStyle duplicating style when it's not neccessary
            }
        }

        .container-inputs-table {
            margin: 0 12px;

            tr th {
                background-color: white;
                color: @alpha-secondary-font-color;
                padding: 12px 5px 12px 15px;
            }

            td {
                min-width: 140px;
                background-color: white;
                z-index: 100;
                padding-left: 15px;
                padding-right: 15px;
            }

            td:hover {
                background-color: white;
            }

            .ant-table-tbody {
                .ant-row.ant-form-item {
                    margin: 0;

                    .ant-form-item-label .alpha-doc-control-name {
                        display: none;
                    }
                }

                .ant-row.ant-form-item.wide:not(
                        .alpha-application-form-buttons
                    ) {
                    width: unset;
                }

                tr td {
                    border-bottom: none;
                    border-top: none;
                }

                tr td:not(:first-of-type) {
                    border-left: none;
                }
            }

            .ant-col.ant-form-item-label {
                padding-bottom: 0px;
                position: relative;
                z-index: 100;
                height: 22px;

                label {
                    display: inline-block;
                    width: 100%;
                }

                .alpha-doc-control-label {
                    span.alpha-icon,
                    i.alpha-icon {
                        margin: 0;
                        position: absolute;
                        right: 0px;
                    }
                }
            }
        }
    }
}

.leftColumnContainer.modal-form {
    display: flex;
    flex-flow: row wrap;

    .result-table {
        .table-actions {
            margin-bottom: 12px;
            text-align: right;
        }
    }

    .ant-row.ant-form-item {
        width: 50%;
        padding-right: 10px;

        .ant-form-item-label {
            padding: 0;

            label {
                font-size: 11px;
                color: #9ba0aa;
                height: auto;
                margin-bottom: 5px;
            }
        }

        input,
        .ant-input-number,
        .ant-picker {
            width: 100%;
        }
    }

    .ant-row.ant-form-item.wide {
        width: 100%;
    }
}

.alpha-document-container.no-left {
    max-width: unset;

    > .leftColumnContainer {
        display: none;
    }

    > .rightColumnContainer {
        display: flex;
        flex-flow: column-reverse;

        .field-highlight-block {
            background-color: @alpha-background-yellow;
        }

        .document-wrapper {
            flex: 0 0 calc(50vh - 80px);
            max-width: 100vw;
            overflow: auto;

            .k-state-disabled {
                opacity: 1;
            }
            // .minimap {
            //   top: 520px;
            //   z-index: 1001;
            // }
        }

        .bottomContainer {
            border-bottom: 1px solid @alpha-border-color;
            position: relative;
            flex: 1 1 500px;
            bottom: unset;
            top: unset;

            .ant-form-item {
                margin-bottom: 0;
            }

            .ant-table-body {
                max-height: calc(50vh - 295px) !important;
            }

            .dynamic-visual-table {
                .ant-table-column-sorters {
                    max-width: 100%;

                    .ant-table-column-title {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .dynamic-visual-table.no-pages {
                .ant-table-body {
                    max-height: calc(50vh - 235px) !important;
                }
            }

            .visual-table-counts {
                color: @alpha-secondary-font-color;
                font-size: 14px;
                line-height: 16px;
                padding: 0px 0px 30px;
            }

            .bottom-container-header {
                color: #000;
                display: flex;
                padding: 30px 30px 0px;
                font-size: 34px;
                font-weight: 500;
                line-height: 40px;

                .alpha-application-form-buttons {
                    border: none;
                    flex: 1 1;
                    padding: 0;

                    .reload-button {
                        color: #9ba0aa;
                        padding: 4px 8px;
                        vertical-align: middle;

                        .alpha-icon {
                            margin: 0;
                            vertical-align: text-top;
                        }
                    }

                    .submit-button {
                        height: 40px;
                        margin-left: 6px;
                        margin-bottom: 0px;
                    }
                }
            }

            .bottom-container-content {
                height: calc(100% - 95px);
                max-width: 100vw;
                padding-top: 0;

                .ant-tabs-content-holder {
                    padding: 12px 25px 12px 30px;
                }
            }

            .bottom-container-content.has-errors {
                height: calc(100% - 168px);
            }

            .alpha-application-form-buttons {
                border-top: 1px solid @alpha-border-color;
                margin-bottom: 0;
                padding: 12px;
            }

            .bottom-container-collapser {
                bottom: 0;
                border-bottom: none;
                border-top: 1px solid @alpha-secondary-border-color;
                margin: 0;
                top: unset;
            }
        }

        .bottomContainer.collapsed {
            height: calc(100vh - 100px) !important;
            overflow: auto;

            .ant-table-body {
                max-height: calc(100vh - 420px) !important;
            }

            .dynamic-visual-table.no-pages {
                .ant-table-body {
                    max-height: calc(100vh - 390px) !important;
                }
            }

            .bottom-container-content {
                height: auto;
                overflow: auto;
            }

            .bottom-container-collapser {
                border-bottom: 1px solid @alpha-secondary-border-color;
                bottom: 35px;
                position: fixed;
            }
        }

        .document-wrapper.bottom-collapsed {
            flex: 0 0 0;
        }

        .bottomContainer.detached.collapsed {
            .bottom-container-collapser {
                visibility: hidden;
            }

            .ant-table-body {
                max-height: calc(100vh - 300px) !important;
            }
        }
    }
    // .bottomContainer {
    //   top: 50px;
    //   bottom: unset;
    // }
}

.scan-results-flex-container {
    .ant-collapse-borderless {
        background-color: #fff;
    }
    .new-line {
        flex-basis: 100%;
        width: 0px;
        height: 0px;
        overflow: hidden;
    }
}

.scan-results-flex-container .ant-collapse-borderless > .ant-collapse-item {
    // border-bottom: 1px solid black;
    border-bottom: unset;
}

.scan-results-flex-container
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    padding: 11px 16px 11px 20px;

    > .ant-collapse-arrow {
        left: 18px;
    }
}

.alpha-document-container:not(.no-left) {
    .bottomContainer {
        .bottom-container-content {
            .bottom-container-header {
                display: flex;
                .app-title {
                    color: #000;
                    flex: 1;
                    padding-left: 10px;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 40px;
                }
            }

            .bottom-container-header + .ant-tabs.ant-tabs-bottom {
                height: calc(100% - 72px);
            }
        }
    }
}

.bottomContainer {
    background: @alpha-secondary-background-color;
    border-top: 1px solid @alpha-secondary-border-color;
    bottom: 35px;
    max-width: 100%;
    position: absolute;
    right: 0;
    transition: 0.2s;
    height: calc(50% - 92px);
    overflow: auto;
    width: 100%;
    z-index: 1001;

    .ant-form-item {
        padding-right: 12px;
    }

    .alpha-doc-control-label.with-controls {
        display: inline-flex;
        width: 100%;

        .alpha-doc-control-name {
            flex: 1 1 100%;
        }

        .dynamic-modal-form-toggle {
            float: none;
            margin-right: 0 !important;
        }

        .confidenece-level {
            float: none !important;
            margin-right: 4px !important;
            margin-left: 4px;
        }

        .dynamic-modal-form-toggle,
        .confidenece-level {
            margin-top: -2px;
        }

        .controls-container {
            align-items: center;
            display: inline-flex;
            justify-content: flex-end;
            height: 15px;
        }
    }

    .ant-form > .ant-row.ant-form-item {
        padding-left: 12px;
        // Dummy styles for Ed's demo
        display: inline-block;
        min-width: 150px;
        // ****
    }

    .ant-row.ant-form-item {
        .ant-form-item-label {
            padding-bottom: 0px;
            label {
                font-size: 11px;
                color: @alpha-secondary-font-color;
                height: auto;
                margin-bottom: 5px;
            }
        }
    }

    .bottom-container-content.tabs-hidden {
        height: 100%;
    }

    .bottom-container-content {
        height: calc(100% - 60px);
        padding-top: 66px;

        .ant-tabs.ant-tabs-bottom {
            height: 100%;

            .ant-tabs-nav {
                bottom: 14px;
                margin-left: 0;
                text-align: center;
                width: 100%;

                .ant-tabs-nav-more {
                    display: none;
                }

                .ant-tabs-nav-list {
                    align-items: center;
                    justify-content: center;
                    padding: 15px 0 0;
                    user-select: none;
                    width: 100%;

                    .ant-tabs-ink-bar {
                        display: none;
                    }

                    .ant-tabs-tab {
                        border-color: @alpha-secondary-border-color;
                        border-style: solid;
                        border-width: 1px 1px 1px 0px;
                        color: @alpha-secondary-font-color;
                        font-size: 14px;
                        margin: 0;
                        padding: 5px 15px;
                    }

                    .ant-tabs-tab:first-child {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        border-left-width: 1px;
                    }

                    .ant-tabs-tab:nth-last-child(2) {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }

                    .ant-tabs-tab.ant-tabs-tab-active {
                        background-color: @primary-color;
                        border-color: @primary-color;

                        .ant-tabs-tab-btn {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .bottom-container-collapser {
        align-items: center;
        background: @alpha-background-gray;
        border-bottom: 1px solid @alpha-secondary-border-color;
        color: #000;
        cursor: pointer;
        display: flex;
        font-weight: 500;
        height: 55px;
        margin-bottom: 12px;
        padding: 2px 16px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1001;

        .collapser-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            margin-left: 15px;
        }

        .collapse-icon {
            transition: 0.2s;
        }

        .ant-tabs-nav {
            transition: 0.4s;
        }
    }

    .bottom-container-collapser:hover {
        background: @alpha-background-gray-dark;
    }

    .ant-tabs.ant-tabs-bottom {
        overflow: auto;
    }

    .container-inputs-table {
        .ant-form-item .ant-col.ant-form-item-label {
            display: none;
        }

        .ant-table-tbody tr td {
            padding: 0;

            .ant-form-item {
                padding-right: 0;

                .ant-input {
                    border-radius: 0;
                }
            }
        }

        .ant-table-tbody tr td:first-child {
            padding: 0 0 0 15px;
        }

        .ant-table-tbody tr td:not(:first-child) {
            .ant-form-item {
                .ant-input {
                    border-left: none;
                }
            }
        }

        .ant-table-tbody tr td:last-child {
            padding: 0 15px 0 0;
        }

        .ant-table-thead tr th:not(:first-child) {
            padding-left: 0;
        }
    }
}

.bottomContainer.collapsed {
    height: 56px !important;
    overflow: hidden;
    transition: 0.2s;

    .bottom-container-content {
        height: 0px;
        overflow: hidden;

        .ant-tabs-nav {
            bottom: -100px;
            transition: 0.4s;
        }
    }

    .collapse-icon {
        transition: 0.2s;
        transform: rotate(180deg);
    }
}

.radio-scan-result-container {
    width: 100%;

    .ant-form-item-label {
        text-align: left;
    }
}

.checkbox-scan-result-container {
    display: inline-flex;
    align-items: center;
    position: relative;
    flex: 1 1 100%;
    height: 16px;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;

    .checkbox-scan-result {
        margin-bottom: 0;
    }

    .text-navigator-container {
        height: 10px;
        margin-top: 8px;
        margin-left: 3px;
    }
}

.loading-ui + .rightColumnContainer {
    .create-contract-document-loading {
        display: none;
    }

    .field-document-block {
        display: none;
    }
}

.scan-results-flex-container .ant-collapse-content > .ant-collapse-content-box {
    margin-left: 30px;
    padding: unset;
    width: calc(100% - 50px);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.scan-results-flex-container .ant-collapse-item {
    overflow: visible;

    .ant-collapse-content {
        overflow: visible;
    }
}

.scan-results-flex-container
    .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding-left: 0;
}

.scan-results-flex-container .ant-collapse .alpha-section-header {
    > .ant-collapse-header {
        background-color: #f5f6f8;
        margin-bottom: 20px;
    }
    .alpha-layer-section-header {
        margin-left: 10px;
    }
    .ant-collapse-header {
        width: calc(100% - 50px);
        margin-left: 30px;
    }
    > .ant-collapse-header {
        margin-top: 10px;
    }
}

.ant-menu-submenu {
    .ant-menu-item.document-tab {
        padding-right: 0;
        span.btn-container {
            padding-right: 0;
        }
        span.tab-title {
            margin-right: 12px;
        }
    }
}

.ant-menu-submenu.ant-menu-submenu-popup {
    .document-tab {
        .tab-title {
            .alpha-icon {
                display: none;
            }

            .title-text {
                vertical-align: middle;
            }

            .title-container {
                display: inline-flex;
                max-width: calc(100% - 20px);
                flex-flow: column;
                transform: translateY(-4px);

                .main-title-text {
                    height: 12px;
                    line-height: 12px;
                    margin-bottom: 2px;
                }

                .subtitle-text {
                    color: @alpha-secondary-font-color;
                    height: 10px;
                    line-height: 10px;
                    font-size: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.ant-layout-header.app-page-header {
    .ant-menu.ant-menu-horizontal {
        .ant-menu-item.document-tab {
            .ant-row {
                line-height: 50px;
            }
        }

        .ant-menu-item.document-tab:after {
            display: none;
        }

        .ant-menu-item.document-tab {
            cursor: pointer;
            font-size: 15px;
            overflow: hidden;
            margin-left: -1px;
            margin-right: 0px;
            padding: 0;

            .tab-title {
                display: inline-block;
                font-size: 14px;
                height: 30px;
                line-height: 30px;
                margin: 10px 0;
                padding: 0 18px 0 15px;
                white-space: nowrap;
                vertical-align: top;
                width: 250px;

                .alpha-icon {
                    font-size: 18px;
                    height: 18px;
                    margin-right: 10px;
                    width: 18px;
                }

                .title-text,
                .alpha-icon {
                    vertical-align: middle;
                }

                .title-text {
                    color: #fff;
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }

                .title-container {
                    display: inline-flex;
                    max-width: calc(100% - 20px);
                    flex-flow: column;
                    transform: translateY(-4px);

                    .main-title-text {
                        color: #fff;
                        height: 12px;
                        line-height: 12px;
                        margin-bottom: 2px;
                    }

                    .subtitle-text {
                        color: @alpha-secondary-font-color;
                        height: 10px;
                        line-height: 10px;
                        font-size: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .btn-container {
                display: inline-block;
                height: 30px;
                line-height: 30px;
                margin: 10px 0 10px auto;
                padding-right: 16px;
                vertical-align: top;

                .document-tab-btn {
                    text-align: center;
                    visibility: hidden;

                    i {
                        margin: 0;
                    }
                }
            }
        }

        .ant-menu-item.document-tab.selected {
            background-color: @primary-color-hover;
        }

        .ant-menu-item.document-tab:hover {
            background-color: @primary-color-hover;

            .btn-container {
                .document-tab-btn {
                    visibility: visible;
                }
            }
        }
    }
}

.ant-menu-submenu {
    .ant-menu-submenu-title {
        font-size: 14px;
    }

    .ant-menu-item.document-tab {
        color: @alpha-font-color-black;
        font-size: 14px;

        .btn-container {
            border: none;
            padding-right: 15px;
            text-align: right;

            .document-tab-btn {
                padding: 0;
            }

            i.alpha-icon.nav-close-tab {
                background-image: url("../modules/common/resources/icons/nav-close-tab-popover.svg");
            }
        }
    }

    .ant-menu-item.document-tab {
        .tab-title {
            max-width: 150px;
        }
    }

    .ant-menu-item.document-tab.ant-menu-item-selected {
        background-color: @alpha-background-gray-dark;
    }

    .ant-menu-item.document-tab:hover {
        background-color: @alpha-background-gray-dark;

        .btn-container {
            .document-tab-btn {
                visibility: visible;
            }
        }
    }
}

.ant-menu-submenu:first-of-type + .document-tab {
    .tab-title {
        border-left: 1px solid @primary-color-hover;
    }
}

.document-tab.selected {
    background-color: @primary-color-hover;
}

.document-tab:hover {
    background-color: @primary-color-hover;

    .btn-container {
        .document-tab-btn {
            visibility: visible;
        }
    }
}

.ant-menu-submenu {
    .document-tab {
        color: @alpha-font-color-black;

        .btn-container {
            border: none;

            i.alpha-icon.nav-close-tab {
                background-image: url("../modules/common/resources/icons/nav-close-tab-popover.svg");
            }
        }
    }

    .document-tab {
        .tab-title {
            max-width: 150px;
        }
    }

    .document-tab.ant-menu-item-selected {
        background-color: @alpha-background-gray;
    }

    .document-tab:hover {
        background-color: @alpha-background-gray;

        .btn-container {
            .document-tab-btn {
                visibility: visible;
            }
        }
    }
}

.ant-table-wrapper.dynamic-visual-table {
    .ant-table {
        .ant-tag {
            border-radius: 10px;
            font-size: 11px;
            font-weight: 500;
            padding: 3px 7px;
            line-height: 11px;
        }

        .ant-table-thead > tr > th {
            background-color: white;
            color: @alpha-secondary-font-color;
            padding: 6px 0;
        }

        .ant-table-thead > tr > td,
        .ant-table-thead .ant-table-column-sorters {
            padding: 6px 8px;
        }

        .ant-table-tbody > tr > td {
            color: #000;
            cursor: pointer;
            padding: 7px 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .ant-tag {
                border-radius: 10px;
            }

            .row-actions {
                text-align: right;
                transition: 0s;
                visibility: hidden;

                button {
                    background: transparent;
                    border: none;
                }
            }
        }

        .ant-table-tbody {
            tr.ant-table-row-selected > td {
                background: @alpha-background-gray;
            }
            tr:hover {
                .row-actions {
                    transition: 0s;
                    visibility: visible;
                }
            }

            tr:hover td.ant-table-cell {
                background: #f5f6f8;
            }

            tr.error-row td.ant-table-cell {
                background: @alpha-background-red;
            }

            tr.error-row:hover td.ant-table-cell {
                background: @alpha-background-red-hover;
            }

            tr.success-row td.ant-table-cell {
                background: @alpha-background-green;
            }

            tr.success-row:hover td.ant-table-cell {
                background: @alpha-background-green-hover;
            }

            tr.highlighted-row td.ant-table-cell {
                background: @alpha-background-yellow;
            }

            tr.highlighted-row:hover td.ant-table-cell {
                background: @alpha-background-yellow-hover;
            }
        }
    }
}

.ant-table-wrapper.dynamic-visual-table.result-table {
    .ant-table-tbody > tr > td {
        padding: 4px 16px;
    }
}

.ant-table-wrapper.dynamic-visual-table.result-table.has-actions {
    .ant-table-tbody > tr {
        cursor: pointer;
    }
}

.dynamic-row-edit-modal {
    .dynamic-row-edit-form {
        max-height: calc(80vh - 160px);
        overflow: auto;

        .input-wrapper {
            margin-bottom: 12px;

            .ant-form-item-no-colon {
                width: 100%;
            }

            .ant-form-item-label {
                padding: 0;
            }

            .input-label {
                align-items: center;
                color: @alpha-font-color-gray;
                display: flex;
                font-size: 12px;
                margin-bottom: 4px;
                width: 100%;

                .label-title {
                    flex: 1 1;
                }
            }
        }

        .ant-select.dynamic-input,
        .ant-picker.dynamic-input {
            width: 100%;
        }
    }
}

.confidenece-level {
    span {
        font-size: 11px;
        color: @alpha-font-color-gray;
    }
}

div.confidence-level-high.ant-picker,
.texteditor-btn.confidence-level-high {
    border: 1px solid @conf-level-high-color;
}

div.confidence-level-average.ant-picker,
.texteditor-btn.confidence-level-average {
    border: 1px solid @conf-level-average-color;
}

div.confidence-level-poor.ant-picker,
.texteditor-btn.confidence-level-poor {
    border: 1px solid @conf-level-poor-color;
}

div.ant-select.confidence-level-high {
    .ant-select-selector {
        border: 1px solid @conf-level-high-color;
    }
}

div.ant-select.confidence-level-average {
    .ant-select-selector {
        border: 1px solid @conf-level-average-color;
    }
}

div.ant-select.confidence-level-poor {
    .ant-select-selector {
        border: 1px solid @conf-level-poor-color;
    }
}

div.ant-input-number.confidence-level-high {
    border: 1px solid @conf-level-high-color;
}

div.ant-input-number.confidence-level-average {
    border: 1px solid @conf-level-average-color;
}

div.ant-input-number.confidence-level-poor {
    border: 1px solid @conf-level-poor-color;
}

input.confidence-level-high {
    border: 1px solid @conf-level-high-color;
}

input.confidence-level-average {
    border: 1px solid @conf-level-average-color;
}

input.confidence-level-poor {
    border: 1px solid @conf-level-poor-color;
}

textarea.confidence-level-high {
    border: 1px solid @conf-level-high-color;
}

textarea.confidence-level-average {
    border: 1px solid @conf-level-average-color;
}

textarea.confidence-level-poor {
    border: 1px solid @conf-level-poor-color;
}

.iota-contract-shadow.texteditor-btn,
textarea.iota-contract-shadow,
textarea.iota-contract-shadow:focus,
input.iota-contract-shadow,
input.iota-contract-shadow:focus,
.iota-contract-shadow.ant-input-number,
.iota-contract-shadow .ant-select-selector,
.ant-picker.iota-contract-shadow,
.iota-contract-shadow input,
.iota-contract-shadow input:focus {
    box-shadow: 0px 0px 1px 2px #83acde;
}

.ant-select.iota-contract-shadow {
    input,
    input:focus {
        box-shadow: none;
    }
}

.iota-contract-shadow.ant-input-number,
.ant-picker.iota-contract-shadow {
    input,
    input:focus {
        box-shadow: none;
    }
}

div.rightColumnContainer {
    flex: 1 1 0;
    min-width: 0;
}

div.minimap-container.alpha-document-minimap {
    overflow: auto;
    width: 100%;
    z-index: 1000;
    height: calc(100vh - 163px);
    .loading-container {
        align-content: center;
        display: flex;
        flex-direction: column;
        height: calc(100% - 50px);
        justify-content: center;
        position: absolute;
        width: calc(100% - 520px);
    }

    .minimap {
        background: transparent;
        border: none;
        margin-top: 60px;
        overflow: auto;
        position: absolute;
        scrollbar-width: none; // Hide scrollbars in FF
        top: 10px;
        transition: 0.2s;
        max-height: calc(100vh - 161px) !important;

        .minimap-viewport {
            border-radius: 4px;
            background-color: rgba(39, 60, 117, 0.15);
            transition: background-color 0.2s;
        }
    }

    .minimap:hover {
        background: #c1c1c11f;
        transition: background-color 0.2s;
        z-index: 100;

        .minimap-viewport {
            background-color: rgba(39, 60, 117, 0.25);
            transition: background-color 0.2s;
        }
    }

    .pdf-content {
        overflow: visible;
    }
}

div.minimap-container.alpha-document-minimap::-webkit-scrollbar,
div.minimap::-webkit-scrollbar {
    background: transparent;
    width: 0px;
}

div.minimap-container.alpha-document-minimap {
    scrollbar-width: none;
}

.documentControlsContainer {
    position: absolute;
    bottom: 15px;
}

.documentControlsContainer > button {
    margin-left: 20px;
}

.ant-modal.dynamic-modal-form {
    .radio-scan-result-container {
        width: 50%;

        .ant-col.ant-form-item-label {
            width: 100%;
        }
    }
}

.iota-app-field-comments {
    max-height: 300px;
    overflow: auto;
}

@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;