.flow-tab-content {
    .flow-type-toggle {
        margin-bottom: 16px;
    }

    .flow-editor-container {
        height: 560px;
        position: relative;
        .flow-properties-panel {
            width: 300px;
            background-color: white;
            border: 1px solid #d9d9d9;
            position: absolute;
            bottom: 0;
            top: 0;
            right: 0;
            display: flex;
            flex-direction: column;
        }

        .flow-scale-toolbar {
            position: absolute;
            bottom: 8px;
            right: 308px;
            width: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            border-radius: 4;
            border: 1px solid #0000000a;

            button {
                width: 30px;
                padding: 6px 8px;
            }

            button:hover {
                background-color: #e0e0e0;
            }
        }
    }
}

.alpha-table.metadata {
    overflow-y: auto;
    height: calc(100% - 58px);
    .ant-table-selection-column {
        .ant-checkbox {
            visibility: hidden;
        }
    }
}

.alpha-table {
    &.task-types,
    &.tasks-templates-list,
    &.action-definitions {
        overflow-y: auto;
        height: calc(100% - 58px);
        .ant-table-selection-column {
            .ant-checkbox {
                visibility: hidden;
            }
        }
    }
}

.layout-with-table {
    height: 100%;
    overflow: hidden;
    background: unset;
}

.statuses-tabs-wrapper .ant-tabs-content-holder {
    overflow-y: auto;
    height: calc(100vh - 220px);
}

i.alpha-icon.project-assignments {
    background-image: url("../modules/common/resources/icons/project-assignments.svg");
}

.at-space-stretch-last {
    .ant-space-item:last-child {
        flex: 1;
    }
}

.at-space-stretch-last-1 {
    .ant-space-item:nth-last-child(2) {
        flex: 1;
    }
}

.at-space-stretch-last-2 {
    .ant-space-item:nth-last-child(3) {
        flex: 1;
    }
}

.metadata-definition-form {
    #trueVal_help {
        .ant-form-item-explain-error {
            position: absolute;
            width: 254px;
        }
    }
    #falseVal_help {
        .ant-form-item-explain-error {
            position: absolute;
            width: 254px;
            right: -33px;
        }
    }
}

@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;