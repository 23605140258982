@import "./variables.less";

.task-properties-modal {
    // Workaround to remove black border appeared afer browser update in edge.
    &.ant-drawer {
        outline: none;
    }
    .panel-header {
        font-size: 17px;

        .title {
            color: @alpha-font-color-black;
            font-weight: 500;
            margin-right: 4px;
        }

        .items-amount {
            color: @alpha-font-color-gray;
        }
    }

    .parent-task-navigation {
        font-size: 12px;
        color: @alpha-font-color-gray;
        cursor: pointer;
    }

    .alpha-task-section-collapse {
        background-color: transparent;

        div.ant-collapse-item {
            margin: 0 0 0 24px;

            div.ant-collapse-header {
                font-size: 14px;
                font-weight: 500;
                padding-left: 0;
            }
        }
    }

    .alpha-task-section-collapse.no-padding {
        .ant-collapse-content {
            .ant-collapse-content-box {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .dialog-content-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: -10px;
        .progress-container {
            margin-left: 24px;
            margin-bottom: 16px;
        }
    }

    .percent-label-container {
        height: 15px;
        .percent-label {
            font-size: 11px;
            color: #848484;
            float: right;
        }
    }

    .dialog-section {
        border-bottom: 1px solid @alpha-border-color;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .sender-avatar {
            img {
                vertical-align: top;
            }
        }

        .ant-collapse-item {
            border: none;
        }

        .ant-collapse-item > .ant-collapse-header {
            padding-left: 31px;
        }

        .ant-collapse-content-box {
            padding-left: 0;
        }

        .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
            left: 6px;
        }

        .attachments-loading-spin {
            align-items: center;
            display: flex;
            height: 120px;
            text-align: center;

            .ant-spin {
                flex: 1 1;
            }
        }

        .attachment {
            cursor: pointer;
            display: inline-block;
            font-size: 13px;
            margin-right: 12px;
            padding: 8px 12px;
            position: relative;
            text-align: center;
            vertical-align: top;
            width: 110px;

            .attachment-remove-button {
                position: absolute;
                right: 0;
                top: 0;
            }

            .attachment-icon {
                background-size: contain;
                background-position: center;
                height: 60px;
                margin-right: 0;
                width: 42px;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }

            svg {
                height: 100%;
                width: 100%;
            }

            .attachment-preview {
                border: 1px solid @alpha-border-color;
                margin-bottom: 12px;
                max-width: 42px;
                max-height: 60px;
            }

            .attachment-name {
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                color: @alpha-font-color-black;
                max-height: 38px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .attachment-size {
                color: @alpha-font-color-gray;
            }
        }

        .attachment.selected {
            background-color: @alpha-background-gray;
        }

        .inputs-container {
            .dialog-field {
                margin-bottom: 20px;

                .ant-form-item-label {
                    line-height: 16px;
                }
            }

            .ant-select,
            .ant-calendar-picker,
            .ant-input,
            .ant-input-number {
                border-radius: 4px;
                width: 100%;
            }
            .value-container {
                padding: 0 12px;
            }
        }

        .meta-value {
            height: 100%;

            .meta-display {
                border-radius: 4px;
                height: 100%;
                padding: 0 12px;
                font-size: 14px;
            }

            .meta-display:hover {
                background-color: #eeeeee;
            }
        }
    }

    .activity-entry {
        display: flex;
        font-size: 13px;
        margin-bottom: 15px;

        .activity-info {
            flex: 1 1;
            word-break: break-word;

            .activity-time {
                color: @alpha-font-color-gray;
                word-break: normal;
            }

            .activity-name {
                color: @alpha-font-color-black;
            }
        }

        .activity-state {
            flex: 0 0 90px;
            text-align: right;

            .ant-tag {
                margin: 0;
            }
        }

        .activity-controls {
            flex: 0 0 60px;
            text-align: right;

            .session-dropdown-btn {
                visibility: hidden;
            }
        }
    }

    .activity-entry:hover {
        .activity-controls {
            .session-dropdown-btn {
                visibility: visible;
            }
        }
    }

    .email-preview {
        color: @alpha-font-color-black;
        background-color: @alpha-background-gray;
        padding: 20px;

        .email-info-container {
            border-bottom: 1px solid @alpha-border-color;
            font-size: 13px;
            margin-bottom: 15px;
            padding-bottom: 20px;

            .email-info-entry {
                .info-entry-label {
                    font-weight: 700;
                    margin-right: 4px;
                }
            }
        }

        .email-body-container {
            font-size: 15px;
        }
    }

    .task-privacy-change {
        cursor: pointer;
        &.disabled {
            color: @alpha-secondary-font-color;
            pointer-events: none;
        }
    }
}

.task-properties-modal.drawer:not(.full-screen) {
    border-radius: 4px;
    position: absolute;
    height: calc(100vh - 184px);
    top: 150px;
    right: 30px !important;

    .dialog-header {
        margin-left: 24px;
        margin-right: 24px;
    }

    .ant-drawer-content {
        border-radius: 4px;
        overflow: hidden;
    }

    .task-activity-entry {
        padding: 10px 24px;
    }

    .comment-input-wrapper {
        border-width: 1px 0 0 0;
    }

    .task-date-warning {
        color: orange;
        font-size: 12px;
        position: absolute;
        top: 20px;
    }
    .ant-drawer-footer {
        padding: 0;
        border-top: none;
    }
}

.task-properties-modal.drawer {
    .drawer-resizer {
        background-color: transparent;
        cursor: col-resize;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: 0.3s;
        width: 6px;
        z-index: 100;
    }

    .dialog-header.title {
        margin-left: 24px;
    }

    .panel-header {
        margin-bottom: 20px;
    }

    // .ant-drawer-content-wrapper {
    .ant-drawer-header {
        .ant-drawer-close {
            right: 20px;
            width: 48px;
        }
    }

    .ant-drawer-body {
        padding-right: 0;
        padding-left: 0;
        position: relative;

        .dialog-content-container {
            padding-right: 25px;
        }
    }

    .comment-input-wrapper {
        background-color: white;
        border-width: 1px 0 0 0;
        position: relative;

        .comment-input {
            border: none;
            border-radius: 0;
            box-shadow: none;
            height: 54px;
            resize: none;

            & + .comment-controls {
                display: none;
            }
        }

        .comment-input:focus,
        .comment-input.has-text {
            height: 64px;
            min-height: 32px;

            & + .comment-controls {
                display: block;
            }
        }

        &.edit-mode {
            .ql-toolbar.ql-snow {
                bottom: -55px;
            }
        }
    }

    .task-comment {
        margin-bottom: 20px;
        padding: 10px 24px 10px 24px;

        .task-comment-header {
            display: flex;
            margin-bottom: 10px;
            font-size: 13px;

            .author-avatar {
                border-radius: 50%;
                display: inline-block;
                flex: 0 0 24px;
                height: 24px;
                margin-right: 10px;
                vertical-align: middle;
                width: 24px;
            }

            .author-username {
                font-weight: 700;
            }

            .comment-date {
                color: @alpha-font-color-gray;
            }

            .comment-dropdown-btn {
                float: right;
                margin-top: 2px;
                visibility: hidden;
            }
        }

        .task-comment-body {
            padding-left: 34px;

            .hyperlink {
                color: @primary-color;
                cursor: pointer;
            }

            .comment-input-wrapper {
                .comment-input {
                    min-height: 32px;
                    height: unset;

                    & + .comment-controls {
                        display: block;
                        position: absolute;
                        bottom: -100px;
                        right: -12px;
                    }
                }
            }
        }
    }

    .task-comment:hover {
        .task-comment-header {
            .comment-dropdown-btn {
                visibility: visible;
            }
        }
    }

    .task-activity-entry {
        font-size: 13px;
        margin-bottom: 20px;
        padding: 10px 0 10px 0px;

        .activity-entry-content {
            display: flex;
            flex-flow: row;

            .author-avatar {
                background-color: @primary-color;
                border-radius: 50%;
                flex: 0 0 24px;
                display: inline-block;
                height: 24px;
                margin-right: 10px;
                vertical-align: middle;
                width: 24px;
            }

            .user-name {
                font-weight: 700;
                margin-right: 6px;
            }

            .activity-message,
            .activity-date {
                color: @alpha-font-color-gray;
            }
        }
    }

    .user-avatar {
        background: lightgray;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
    }

    .user-avatar.mini {
        height: 24px;
        width: 24px;
    }

    .borderless-select,
    .ant-select.priority-select {
        .ant-select-selector {
            border: 1px solid transparent;
            box-shadow: none;

            .ant-select-selection-item {
                margin-left: 0px;
            }
        }

        .ant-select-arrow {
            visibility: hidden;
        }
    }

    .borderless-select:hover,
    .ant-select.priority-select:hover {
        .ant-select-selector {
            border: 1px solid @alpha-border-color;
            border-radius: 0;
        }

        .ant-select-arrow {
            visibility: visible;
        }
    }

    .borderless-select.required {
        .ant-select-selector {
            border: 1px solid @alpha-color-danger;
            border-radius: 0;
        }
    }

    .borderless-picker.ant-picker,
    .ant-time-picker {
        border: none;
        box-shadow: none;
        padding-left: 12px;
        margin-left: -12px;
    }

    .borderless-picker.icon-left {
        .ant-picker-input {
            box-shadow: none;
            margin-left: 0px;
            padding: 0px 4px 0 22px;
        }

        .ant-picker-suffix {
            left: 0;
            margin: 0;
            top: 3px;
            position: absolute;
        }
    }

    .borderless-input {
        border: none;
        box-shadow: none;
    }

    .input-row {
        line-height: 32px;
        margin-bottom: 8px;

        .input-label {
            color: @alpha-font-color-gray;
            font-size: 13px;
            padding: 0 12px;
        }
    }

    .empty-message {
        color: @alpha-font-color-gray;
        font-size: 13px;
        margin: 40px 0;
        text-align: center;
    }

    .dialog-section {
        padding: 0 0 20px;

        .attachment-container {
            position: relative;

            .attachment {
                display: flex;
                flex-wrap: wrap;
                text-align: left;
                padding: 8px 12px 8px 8px;
                width: unset;

                .attachment-name {
                    margin-bottom: 5px;
                    max-width: calc(100% - 70px);
                }

                .attachment-size {
                    flex-basis: 100%;
                    padding-left: 32px;
                }

                .attachment-icon {
                    height: 18px;
                    margin-right: 16px;
                    width: 16px;
                }
            }

            .menu-container {
                position: absolute;
                right: 48px;
                top: 10px;
                visibility: hidden;
            }
        }

        .attachment-container:hover,
        .attachment-container.loading {
            .menu-container {
                visibility: visible;
            }
        }
    }

    .ant-collapse-item {
        .dialog-section {
            border: none;
            padding: 0;
        }
    }
}

.task-link {
    &:hover {
        .task-link-menu {
            visibility: visible;
        }
    }
    .task-link-menu {
        margin-left: auto;
        visibility: hidden;
    }
    .task-link-menu.active {
        visibility: visible;
    }
    .subtask-icon {
        vertical-align: middle;
    }
    .ant-col {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.task-link-title-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.quill.disabled {
    .ql-toolbar {
        visibility: collapse;
    }

    .ql-container,
    .ql-editor {
        cursor: not-allowed !important;
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #e4e5ed;
    }

    .ql-editor > * {
        cursor: not-allowed !important;
    }
}

.task-properties-modal.drawer .description-input-wrapper,
.comment-in-modal .comment-input-container {
    position: relative;

    .description-button-container {
        position: absolute;
        top: 0;
        right: -7px;
    }
}

.task-properties-modal.drawer:hover {
    .drawer-resizer {
        background-color: #f1f1f1;
        transition: 0.3s;
    }
}

.task-properties-modal.drawer.full-screen {
    overflow: hidden;
    background-color: white;
    margin: -5px -40px 0 -40px;
    min-height: calc(100vh - 80px);
    .dialog-content-container {
        height: 97%;
        .dialog-header {
            margin-top: 10px;
        }
    }
    .comments-container {
        overflow: auto;
        max-height: calc(100vh - 270px);
    }

    .task-date-warning {
        color: orange;
        font-size: 12px;
        position: absolute;
        top: 20px;
    }

    .alpha-task-section-collapse {
        background-color: transparent;

        div.ant-collapse-item {
            border-bottom: 1px solid #e4e5ed;
            margin: 0 0 24px 0 !important;
        }
    }

    .activity-entry {
        .activity-info {
            flex: unset;
        }

        .activity-state {
            padding: 0 6px;
        }
    }

    .task-col {
        min-height: 100%;
        button {
            box-shadow: none;
        }
        border-right: 20px solid @alpha-background-gray;
        .expanded-task-container {
            height: 100%;
            max-height: calc(100vh - 42px);
            padding: 20px 20px 20px 40px;
            overflow: hidden;
            box-shadow: 0px 1px 4px 0px #00000026;
        }
    }

    .ant-tabs {
        .ant-tabs-nav {
            margin: 0;
        }
    }

    .task-comment {
        margin-bottom: 20px;
        padding: 10px 24px 10px 24px;
    }

    .dialog-section {
        margin: 0 0 20px;
        .attachments-content {
            display: flex;
            flex-flow: row wrap;

            .empty-message {
                flex: 0 0 100%;
            }

            .attachment-container {
                flex: 0 0 50%;
                max-width: 50%;
                margin: 0;

                .menu-container {
                    right: 24px;
                }
            }
        }
        .assign-task {
            max-width: calc(100% - 50px);
            margin-left: 11px;
            width: 100%;
            color: #9ba0aa;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .large-comments-container {
        height: calc(100vh - 42px);
        padding: 0 20px 90px;
        box-shadow: 0px 1px 4px 0px #00000026;
    }
    .comment-input-container {
        background: @alpha-background-gray;
        bottom: 0px;
        left: 0;
        padding: 0;
        position: absolute;
        width: 100%;
        z-index: 1000;
        .comment-input-wrapper {
            border-top: none;
        }
        .comment-controls {
            padding: 10px 26px;
            text-align: right;
        }
    }
}
.task-properties-modal.drawer {
    button {
        font-size: 11px;
    }
    .ant-drawer-title,
    .ant-row {
        .repository-button {
            margin-left: 8px;
            button {
                color: #273c75;
                border-color: #273c75;
            }
        }
        i.cross {
            margin-right: 10px;
        }
    }
    .activity-tabs-wrapper {
        .ant-tabs-nav-more {
            display: none;
        }
    }
    .comment-controls {
        padding: 10px 10px;
        text-align: right;
    }
}

.query-button {
    margin-left: 8px;
    button {
        color: #ff2929;
        border-color: #ff2929;
    }
}
.accept-button {
    margin-left: 8px;
    button {
        color: #00ab11;
        border-color: #00ab11;
    }
}

.task-properties-modal.drawer.ant-drawer-open {
    .ant-drawer-content-wrapper {
        .ant-drawer-body {
            overflow-y: hidden;
        }
    }
    .ant-drawer-header button {
        box-shadow: none;
    }
}
.task-properties-modal.drawer.ant-drawer-open,
.task-properties-modal.drawer.full-screen {
    .task-status {
        font-size: 11px;
        border: none;
        border-radius: 4px;
        color: #fff;
    }
    .status-switch {
        margin-left: 1px;
        border-radius: 0px 4px 4px 0px;
        cursor: pointer;
    }
    .task-status-control {
        .task-status-container {
            display: flex;
        }
        .status-switch .tasks-arrow-right {
            margin-top: 10px;
            margin-left: 5px;
        }
    }
}

.linked-tasks-table {
    tr:last-of-type td {
        border-bottom: none;
    }
}

.task-properties-modal.create-dialog {
    .dialog-section {
        overflow-x: hidden;
    }
    .ant-modal-header {
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 13px;
    }

    .ant-modal-body {
        max-height: calc(100vh - 140px);
        overflow: auto;
    }

    .ant-modal-footer {
        padding: 15px 0px 0px 0px;

        button {
            height: 40px;
        }
    }

    .dialog-section {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 5px;
    }

    .dialog-field {
        display: block;
        margin-bottom: 24px;

        .ant-legacy-form-item-label {
            line-height: 18px;
        }
    }

    .ant-upload.ant-upload-drag {
        border: none;
        background-color: transparent;
        text-align: left;

        .ant-upload-btn {
            padding: 0;
        }

        .ant-upload-drag-container {
            color: @alpha-secondary-font-color;
        }
    }

    .attachment {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        padding: 8px 36px 8px 24px;
        width: unset;

        .attachment-name {
            margin-bottom: 5px;
            max-width: calc(100% - 70px);
            padding-left: 16px;
        }

        .attachment-size {
            flex-basis: 100%;
            padding-left: 16px;
        }

        .attachment-icon {
            height: 18px;
            left: 0;
            position: absolute;
            top: 18px;
            width: 16px;
        }

        .attachment-remove-button {
            top: 16px;
        }
    }

    .ant-collapse {
        .ant-collapse-header {
            align-items: center;
            display: flex;
            padding-left: 0;

            .panel-header {
                margin-left: 12px;
                width: 100%;
            }
        }
    }

    .attachments-container {
        margin-top: 34px;
        margin-bottom: 20px;
        font-weight: 500;
    }
}

.tasks-content-container {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 9px;

    .tasks-side-menu {
        flex: 0 0 270px;
        margin: 0px 20px 0 -40px;

        .tasks-side-menu-item-group {
            margin-bottom: 30px;

            .group-header {
                padding-left: 40px;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 8px;
            }

            .tasks-side-menu-item {
                color: #a5a5a5;
                cursor: pointer;
                height: 40px;
                line-height: 40px;
                padding-left: 40px;
                transition: 0.3s;

                .alpha-icon,
                .menu-label {
                    vertical-align: baseline;
                }

                .alpha-icon {
                    margin-bottom: -1px;
                }

                .ant-badge {
                    float: right;
                    margin: 10px 10px 0 0;
                    vertical-align: middle;
                }
            }

            .tasks-side-menu-item:hover {
                background: #e7ecf3;
                transition: 0.3s;
            }

            .tasks-side-menu-item.active {
                background: #dfe4ec;
                color: @alpha-font-color-black;
                transition: 0.3s;
            }
        }
    }

    .tasks-list {
        width: 100%;
        border-radius: 4px;
        flex: 1 1;
        max-height: calc(100vh - 146px);
        &.grouped {
            max-height: calc(100vh - 176px);
        }
        .ant-card.content-card {
            margin-top: 0;
        }
        .assigned-to {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .user {
                margin-left: 2px;
            }
        }
        .ant-table-row:not(.closed) {
            .task-name {
                color: #000000;
            }
        }
        .task-name-info-icons {
            margin-left: 6px;
            display: inline-flex;
            position: absolute;
            top: 15px;
            .info-sub-container {
                display: inline-flex;
                align-items: center;
                margin-right: 9px;
                i {
                    cursor: pointer;
                }
            }
            .subtask-count {
                font-size: 10px;
            }
        }
        .inner-table .ant-table-row.ant-table-row-level-0 {
            .ant-table-selection-column {
                label {
                    display: none;
                }
            }
            td:nth-child(2) {
                padding-left: 40px;
            }
        }
        .task-button {
            padding-left: 0px;
            padding-right: 0px;
            min-height: 33px;
            max-width: 50%;
            &.name {
                max-width: 50%;
            }
        }
        &.grouped {
            overflow: auto;
        }
        td {
            white-space: nowrap;
        }
    }

    .scrollbar-container.tasks-list.ps {
        .ps__rail-y,
        .ps__rail-x {
            z-index: 10;
        }
    }
}

.task-preview-content {
    padding: 6px 24px;

    .task-preview-item {
        margin: 20px 0;
        text-align: center;
    }
}

.tasks-content-header {
    margin-left: 35px;
    .widget-name-button {
        height: 40px;
        margin-right: 20px;
        .widget-label {
            display: inline-block;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100px;
            vertical-align: middle;
        }
        i {
            vertical-align: middle;
            margin-left: 8px;
        }
        &:hover {
            background: #273c75;
        }
    }
    .projects-select {
        align-self: center;
        margin-right: 4px;
        * {
            color: @alpha-font-color-gray;
        }
        .ant-select-selector {
            background-color: transparent;
            box-shadow: none !important;
            border: none;
        }
        i {
            margin-top: 5px;
            // margin-right: 10px;
        }
    }
    .tasks-header-controls-container {
        flex: 1 1;

        .reset-button {
            color: @alpha-font-color-gray;
            &:not(:disabled) {
                &:hover {
                    color: @primary-color-hover;
                    background: none;
                    i.alpha-icon.refresh {
                        background-image: url("../modules/common/resources/icons/refresh-hover.svg");
                    }
                }
            }
        }

        .new-task-btn {
            height: 40px;
            line-height: 14px;
            padding: 12px 15px;
            margin-left: 15px;
        }

        .new-task-btn:hover {
            background-color: @primary-color-hover;
        }

        .dropdown-btn:not(:hover) {
            color: @alpha-secondary-font-color;
            transition: 0s;
        }

        .dropdown-btn {
            box-shadow: none;
            transition: 0s;
        }
        .tasks-filter-switch {
            margin-right: 18px;
            white-space: nowrap;
            .title {
                color: #9ba0aa;
                margin-left: 8px;
            }
        }
        .ant-col {
            margin-bottom: 10px;
        }
    }
}
.tasks-filter-switch {
    margin-right: 18px;
    white-space: nowrap;
    .title {
        color: #9ba0aa;
        margin-left: 8px;
    }
}

.tasks-header-controls {
    display: inline-block;

    .selected-items {
        color: #a5a5a5;
        margin-right: 20px;
    }

    .header-control {
        cursor: pointer;
        color: @alpha-font-color-black;
        line-height: 16px;
        margin-right: 30px;

        .alpha-icon {
            margin-bottom: -2px;
            margin-right: 8px;
        }
    }

    .header-control.disabled {
        cursor: not-allowed;
        color: @alpha-font-color-gray;
    }
}
.task-columns-menu ul {
    width: 270px;
    overflow: auto;
    max-height: calc(100vh - 65px);
    .draggable-item {
        display: inline-block;
        span {
            vertical-align: bottom;
            display: inline-block;
            width: 160px;
        }
    }
    .droptarget.highlighted > div {
        border-top: 1px dashed rgb(116, 172, 224);
        border-bottom: 1px dashed rgb(116, 172, 224);
    }
}

.ant-select.priority-select.ant-select-single {
    div.ant-select-selector {
        background-color: transparent;
        border: none;
        box-shadow: none;

        .ant-select-selection-item {
            margin-left: 0;
            .alpha-info-tag {
                margin-left: 5px;
            }
        }
    }

    .ant-select-arrow {
        visibility: hidden;
    }

    i.alpha-icon {
        vertical-align: text-top;
    }
}

.ant-select.priority-select.LOW {
    color: #a5a5a5;
}

.ant-select.priority-select.MEDIUM {
    color: #ffce1b;
}

.ant-select.priority-select.HIGH {
    color: @alpha-color-danger;
}

.task-access-dropdown-item {
    padding-bottom: 5px;
    padding-top: 5px;
    i {
        vertical-align: super;
    }
    .description {
        font-size: 12px;
        color: @alpha-secondary-font-color;
    }
    > div {
        display: inline-block;
    }
}

.assign-to-dropdown {
    .limited-access-description {
        font-size: 12px;
        color: @alpha-secondary-font-color;
        padding: 12px;
        display: none;
    }
    .limited-access-description.visible {
        display: block;
    }
}

.alpha-icon.access-type {
    cursor: pointer;
    filter: invert(81%) sepia(11%) saturate(225%) hue-rotate(182deg)
        brightness(78%) contrast(89%);
    &.disabled {
        pointer-events: none;
    }
}

.tasks-list table tr .ant-table-selection-column {
    text-align: left;
    padding-right: 5px;
}
.alpha-table.alpha-table.ant-table-wrapper.sm-rows {
    .ant-table-tbody > tr > td.ant-table-selection-column {
        padding-right: 5px;
    }
}

.tasks-list {
    th.ant-table-selection-column {
        div {
            padding-left: 30px;
        }
    }
    .subtask-indicator {
        cursor: pointer;
        display: inline-block;
        margin-right: 13px;
        width: 12px;
        i {
            vertical-align: text-top;
        }
    }
    .alpha-table.nested-table.ant-table-wrapper {
        .inner-table {
            td:first-of-type {
                padding-left: 40px;
            }
        }
        .ant-table-tbody > tr > td,
        .ant-table-thead > tr > th {
            &.ant-table-selection-column {
                padding-left: 14px;
            }
        }
    }
}

.task-status-menu ul {
    width: 230px;
    div {
        height: 10px;
        width: 10px;
        display: inline-block;
        border-radius: 2px;
        margin-right: 10px;
    }
}

.sub-task-name {
    color: #273c75;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.task-preview-header {
    .ant-col {
        padding-bottom: 10px;
    }
    .buttons-container {
        height: 19px;
    }
}

.ant-collapse-item,
.task-properties-modal.drawer.full-screen {
    .sub-task-item {
        margin-top: 10px;
        .color-label {
            width: 10px;
            height: 10px;
            border: 1px solid black;
            display: inline-block;
        }
        .details {
            display: inline-block;
            margin-left: 14px;
            .date-container .ant-picker {
                margin-left: -6px;
                background: #fff;
                padding-top: 0px;
            }
            .ant-avatar {
                vertical-align: text-top;
            }
            .user {
                vertical-align: text-top;
            }
        }
        .ant-col {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            i {
                vertical-align: middle;
            }
        }
    }
    .sub-task-item:not(:last-of-type) {
        margin-bottom: 7px;
    }
}

.activity-message,
.task-comment-body,
.description-input-wrapper {
    p {
        margin-bottom: 0;
    }
}

.paging-container {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: right;
}

.override-title-button > span {
    position: absolute;
    top: -25px;
    font-size: 11px;
    left: 0;
}

.react-resizable {
    position: relative;
    background-clip: padding-box;
}

.react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
}

.at-task-actions {
    height: calc(100vh - 104px);
    overflow-y: auto;
    margin-top: 10px;

    .ant-list-item {
        .ant-list-item-meta {
            align-items: center;

            .ant-list-item-meta-title .attachment {
                margin-left: 8px;
            }
        }
        .ant-space-item .anticon {
            font-size: 18px;
        }
        .ant-space-item:not(:last-child) .anticon {
            color: #9ba0aa;
        }
    }
}

.at-object-table {
    border-collapse: collapse;

    tr:not(:last-child) {
        border-bottom: 1px solid #dddddd;
    }

    td {
        padding: 4px 8px;
    }

    td:first-child {
        font-weight: bolder;
        border-right: 1px solid #dddddd;
    }
}
.tasks-header-controls-container {
    .header-title {
        font-size: 28px;
        font-weight: 500;
        line-height: 33px;
    }
}

i.alpha-icon.add-attachment {
    background-image: url("../modules/tasks/resources/icons/add-attachment.svg");
}

i.alpha-icon.filter-abc {
    background-image: url("../modules/tasks/resources/icons/filter-abc.svg");
}

i.alpha-icon.filter-abc-active {
    background-image: url("../modules/tasks/resources/icons/filter-abc-active.svg");
}

i.alpha-icon.filter-all-tasks {
    background-image: url("../modules/tasks/resources/icons/filter-all-tasks.svg");
}

i.alpha-icon.filter-all-tasks-active {
    background-image: url("../modules/tasks/resources/icons/filter-all-tasks-active.svg");
}

i.alpha-icon.filter-archive {
    background-image: url("../modules/tasks/resources/icons/filter-archive.svg");
}

i.alpha-icon.filter-archive-active {
    background-image: url("../modules/tasks/resources/icons/filter-archive-active.svg");
}

i.alpha-icon.filter-creation-date {
    background-image: url("../modules/tasks/resources/icons/filter-creation-date.svg");
}

i.alpha-icon.filter-creation-date-active {
    background-image: url("../modules/tasks/resources/icons/filter-creation-date-active.svg");
}

i.alpha-icon.filter-due-date {
    background-image: url("../modules/tasks/resources/icons/filter-due-date.svg");
}

i.alpha-icon.filter-due-date-active {
    background-image: url("../modules/tasks/resources/icons/filter-due-date-active.svg");
}

i.alpha-icon.filter-my-tasks {
    background-image: url("../modules/tasks/resources/icons/filter-my-tasks.svg");
}

i.alpha-icon.filter-my-tasks-active {
    background-image: url("../modules/tasks/resources/icons/filter-my-tasks-active.svg");
}

i.alpha-icon.group-assignee {
    background-image: url("../modules/tasks/resources/icons/group-by-assignee.svg");
}

i.alpha-icon.group-assignee-active {
    background-image: url("../modules/tasks/resources/icons/group-by-assignee-active.svg");
}

i.alpha-icon.group-priority {
    background-image: url("../modules/tasks/resources/icons/group-by-priority.svg");
}

i.alpha-icon.group-priority-active {
    background-image: url("../modules/tasks/resources/icons/group-by-priority-active.svg");
}

i.alpha-icon.group-status {
    background-image: url("../modules/tasks/resources/icons/group-by-status.svg");
}

i.alpha-icon.group-status-active {
    background-image: url("../modules/tasks/resources/icons/group-by-status-active.svg");
}

i.alpha-icon.group-list {
    background-image: url("../modules/tasks/resources/icons/group-list.svg");
}

i.alpha-icon.group-list-active {
    background-image: url("../modules/tasks/resources/icons/group-list-active.svg");
}

i.alpha-icon.nav-tasks {
    background-image: url("../modules/tasks/resources/icons/tasks.svg");
}

i.alpha-icon.action-more {
    background-image: url("../modules/tasks/resources/icons/action-more.svg");
}

i.alpha-icon.action-more-dots {
    background-image: url("../modules/tasks/resources/icons/action-more-dots.svg");
}

i.alpha-icon.action-more-dots-active {
    background-image: url("../modules/tasks/resources/icons/action-more-dots-active.svg");
}

i.alpha-icon.action-remove {
    background-image: url("../modules/tasks/resources/icons/action-remove.svg");
}

i.alpha-icon.task-link {
    background-image: url("../modules/tasks/resources/icons/task-link.svg");
}

i.alpha-icon.task-link-active {
    background-image: url("../modules/tasks/resources/icons/task-link-active.svg");
}

i.alpha-icon.task-full-screen {
    background-image: url("../modules/tasks/resources/icons/task-full-screen.svg");
}

i.alpha-icon.task-full-screen-active {
    background-image: url("../modules/tasks/resources/icons/task-full-screen-active.svg");
}

i.alpha-icon.priority-low {
    background-image: url("../modules/tasks/resources/icons/priority-low.svg");
}

i.alpha-icon.priority-medium {
    background-image: url("../modules/tasks/resources/icons/priority-medium.svg");
}

i.alpha-icon.priority-high {
    background-image: url("../modules/tasks/resources/icons/priority-high.svg");
}

i.alpha-icon.action-check-active {
    background-image: url("../modules/tasks/resources/icons/action-check-active.svg");
}

i.alpha-icon.table-view {
    background-image: url("../modules/tasks/resources/icons/table-view.svg");
}

i.alpha-icon.table-group {
    background-image: url("../modules/tasks/resources/icons/table-group.svg");
}

i.alpha-icon.table-search {
    background-image: url("../modules/tasks/resources/icons/table-search.svg");
}

.ant-dropdown-open,
.dropdown-btn:hover {
    i.alpha-icon.table-view {
        background-image: url("../modules/tasks/resources/icons/table-view-active.svg");
    }

    i.alpha-icon.table-group {
        background-image: url("../modules/tasks/resources/icons/table-group-active.svg");
    }

    i.alpha-icon.columns {
        background-image: url("../modules/tasks/resources/icons/columns-active.svg");
    }
}

i.alpha-icon.notification-assigned-task {
    background-image: url("../modules/tasks/resources/icons/notification-assigned-task.svg");
}

i.alpha-icon.notification-assigned-task.seen {
    background-image: url("../modules/tasks/resources/icons/notification-assigned-task-seen.svg");
}

i.alpha-icon.notification-attached-file {
    background-image: url("../modules/tasks/resources/icons/notification-attached-file.svg");
}

i.alpha-icon.notification-attached-file.seen {
    background-image: url("../modules/tasks/resources/icons/notification-attached-file-seen.svg");
}

i.alpha-icon.notification-due-date {
    background-image: url("../modules/tasks/resources/icons/notification-due-date.svg");
}

i.alpha-icon.notification-due-date.seen {
    background-image: url("../modules/tasks/resources/icons/notification-due-date-seen.svg");
}

i.alpha-icon.notification-new-comment {
    background-image: url("../modules/tasks/resources/icons/notification-new-comment.svg");
}

i.alpha-icon.notification-new-comment.seen {
    background-image: url("../modules/tasks/resources/icons/notification-new-comment-seen.svg");
}

i.alpha-icon.notification-new-priority {
    background-image: url("../modules/tasks/resources/icons/notification-new-priority.svg");
}

i.alpha-icon.notification-new-priority.seen {
    background-image: url("../modules/tasks/resources/icons/notification-new-priority-seen.svg");
}

i.alpha-icon.notification-new-task {
    background-image: url("../modules/tasks/resources/icons/notification-new-task.svg");
}

i.alpha-icon.notification-new-task.seen {
    background-image: url("../modules/tasks/resources/icons/notification-new-task-seen.svg");
}

i.alpha-icon.everyone-access {
    background-image: url("../modules/tasks/resources/icons/everyone-access.svg");
}

i.alpha-icon.limited-access {
    background-image: url("../modules/tasks/resources/icons/limited-access.svg");
}

i.alpha-icon.tasks-arrow-right {
    background-image: url("../modules/tasks/resources/icons/tasks-arrow-right.svg");
}

@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;