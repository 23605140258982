@import "./variables.less";

.notifications-container {
    .notifications-read-button {
        color: @alpha-font-color-gray;
    }
    .notifications-list {
        scrollbar-color: #f5f6f8 #f5f6f8;
        &:hover {
            scrollbar-color: #c2c2c2 #f5f6f8;
        }
    }
}

@primary-color: #273C75;@link-color: #273C75;@border-radius-base: 4px;@item-hover-bg: #F5F6F8;@font-family: "Roboto";@text-color: #000000;@select-item-selected-bg: #F5F6F8;@border-color-base: #E4E5ED;